import { combineReducers, Reducer } from "redux";
import { PURGE } from "redux-persist";
import axios from "axios";
import { RootState as RT } from "./state";
import { authReducer, UNAUTHORIZED } from "./auth";
import { noteReducer } from "./notification";
import { Reducer as userReducer } from "./user";
import { Reducer as menuReducer } from "./menu";
import { Reducer as clinicReducer } from "./clinic";
import { Reducer as ScheduleReducer } from "./schedule";
import { Reducer as DoctorReducer } from "./doctor";
import { Reducer as DocumentReducer } from "./document";
import { Reducer as locationReducer } from "./location";
import { Reducer as appointmentTypeReducer } from "./appointmentType";
import { Reducer as appointmentFormsReducer } from "./appointmentForms";
import { Reducer as marketplaceReducer } from "./marketplace";
import { Reducer as billingReducer } from "./billing";
import { Reducer as procedureReducer } from "./procedure";
import { Reducer as patientReducer } from "./patient";
import { Reducer as clinicPreferencesReducer } from "./clinicPreferences";
import { Reducer as prescriptionLayoutReducer } from "./prescriptionLayout";
import { Reducer as eventsReducer } from "./events";
import { Reducer as socketReducer } from "./socket";
import { Reducer as filesReducer } from "./files";
import { Reducer as messagingReducer } from "./messaging";
import { Reducer as triggerActionsReducer } from "./triggerActions";
import { Reducer as mediaPostReducer } from "./mediaPost";
import { Reducer as appointmentInstructionReducer } from "./appointmentInstruction";
import { Reducer as medicalHistoryReducer } from "./medicalHistory";
import { Reducer as financialsReducer } from "./financials";
import { Reducer as immediateCareReducer } from "./immediateCare";
import { Reducer as realtimeReducer } from "./realtime";
import { Reducer as sharedResourceReducer } from "./sharedResource";
import { Reducer as dashboardReducer } from "./dashboard";
import { Reducer as clinicApiKeyReducer } from "./clinicApiKey";

export type RootState = RT;

export interface Action {
  type: string;
  payload: any;
}

export const reducers: Reducer<RootState> = combineReducers<RootState>({
  auth: authReducer,
  notification: noteReducer,
  user: userReducer,
  menu: menuReducer,
  clinic: clinicReducer,
  schedule: ScheduleReducer,
  doctor: DoctorReducer,
  document: DocumentReducer,
  location: locationReducer,
  appointmentType: appointmentTypeReducer,
  appointmentForms: appointmentFormsReducer,
  marketplace: marketplaceReducer,
  billing: billingReducer,
  procedure: procedureReducer,
  patient: patientReducer,
  clinicPreferences: clinicPreferencesReducer,
  prescriptionLayout: prescriptionLayoutReducer,
  events: eventsReducer,
  socket: socketReducer,
  files: filesReducer,
  messaging: messagingReducer,
  triggerActions: triggerActionsReducer,
  mediaPost: mediaPostReducer,
  appointmentInstruction: appointmentInstructionReducer,
  medicalHistory: medicalHistoryReducer,
  financials: financialsReducer,
  immediateCare: immediateCareReducer,
  realtime: realtimeReducer,
  sharedResource: sharedResourceReducer,
  dashboard: dashboardReducer,
  clinicApiKey: clinicApiKeyReducer,
});

axios.defaults.headers = {
  "Content-Type": "application/json",
};

export const rootReducer = (state: RootState, action: any) => {
  if (action.type === UNAUTHORIZED) {
    state = reducers(state, { type: PURGE });
  }

  return reducers(state, action);
};

export type Error = any;
