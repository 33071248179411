import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  ClinicApiKey,
  ClinicApiKeyFilter,
  ClinicApiKeyForm,
} from "@udok/lib/api/models";
import {
  makeItemDelete,
  makeItemFetch,
  makeItemPost,
  makeItemsFetch,
} from "./axios";

export const createClinicApiKey = async (
  token: string,
  data: ClinicApiKeyForm
) =>
  makeItemPost<ClinicApiKey, ClinicApiKeyForm>({
    url: `/api/clinic-api-key`,
    errLabel: "cake1",
    token,
    data,
  });

export const fetchClinicApiKeys = (token: string, f?: ClinicApiKeyFilter) => {
  const filter = f ? toQueryString(f ?? { limit: 1000 }) : "";
  return makeItemsFetch<ClinicApiKey[]>({
    url: `/api/clinic-api-key${filter}`,
    errLabel: "cake2",
    token,
  });
};

export const fetchClinicApiKey = (token: string, cakeID: string) =>
  makeItemFetch<ClinicApiKey>({
    url: `/api/clinic-api-key/${cakeID}`,
    errLabel: "cake3",
    token,
  });

export const deleteClinicApiKey = (token: string, cakeID: string) =>
  makeItemDelete<ClinicApiKey>({
    url: `/api/clinic-api-key/${cakeID}`,
    errLabel: "cake4",
    token,
  });
