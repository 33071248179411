import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  ClinicApiKey,
  ClinicApiKeyFilter,
  ClinicApiKeyForm,
} from "@udok/lib/api/models";
import {
  createClinicApiKey,
  fetchClinicApiKeys,
  deleteClinicApiKey,
} from "@udok/lib/api/clinicApiKey";
import { getToken, UNAUTHORIZED } from "./auth";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  clinicApiKeyByID: { [cakeID: string]: ClinicApiKey };
};

// Reducers
const initialState: InitialState = {
  clinicApiKeyByID: {},
};

class ClinicApiKeySlice extends Hen<InitialState> {
  clinicApiKeysLoaded(v: ClinicApiKey[]) {
    v.forEach((s) => {
      this.state.clinicApiKeyByID[s.cakeID] = s;
    });
  }

  clinicApiKeyLoaded(v: ClinicApiKey) {
    this.state.clinicApiKeyByID[v.cakeID] = v;
  }
  doctorRemoved(v: ClinicApiKey) {
    delete this.state.clinicApiKeyByID[v.cakeID];
  }
}

export const [Reducer, actions] = hen(new ClinicApiKeySlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.clinicApiKey;

export const clinicApiKeyListView = createSelector(mainSelector, (state) => {
  const list = Object.keys(state?.clinicApiKeyByID || {})
    .map((cakeID) => state.clinicApiKeyByID[cakeID])
    .sort(function (a, b) {
      return moment(b.createdAt).diff(moment(a.createdAt));
    });

  return {
    list: list,
  };
});

export const getAllClinicApiKeys = createSelector(mainSelector, (state) => {
  return {
    list: (Object.keys(state.clinicApiKeyByID)
      .map((cakeID) => state.clinicApiKeyByID[cakeID])
      .filter((cc) => !!cc) ?? []) as ClinicApiKey[],
  };
});

//Actions
export function loadAllClinicApiKeys(
  f?: ClinicApiKeyFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchClinicApiKeys(apiToken, f)
      .then((r) => {
        dispatch(actions.clinicApiKeysLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createNewClinicApiKey(
  clinicApiKey: ClinicApiKeyForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createClinicApiKey(apiToken, clinicApiKey)
      .then((r) => {
        dispatch(actions.clinicApiKeyLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeClinicApiKey(cakeID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteClinicApiKey(apiToken, cakeID)
      .then((r) => {
        dispatch(actions.doctorRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
